import { CheckCircledIcon } from "@radix-ui/react-icons";
import { useTheme } from "next-themes";
import { PropsWithChildren, useEffect, useRef } from "react";
import { Toaster as Sonner, toast } from "sonner";
import { Button } from "./button";
import { Link } from "@remix-run/react";

export type ServerSentToast = {
  type: "success" | "error";
  title?: string;
  id: string;
};

type ToasterProps = React.ComponentProps<typeof Sonner> & {
  serverToast?: ServerSentToast;
};

const Toaster = ({ serverToast, ...props }: ToasterProps) => {
  const { theme = "system" } = useTheme();

  const sentToast = useRef<string | null>(null);
  useEffect(() => {
    if (!serverToast || serverToast.id === sentToast.current) return;
    sentToast.current = serverToast.id;
    toast(serverToast.title, {
      icon:
        serverToast.type === "success" ?
          <SuccessToastIcon />
        : <ErrorToastIcon />,
    });
  }, [serverToast]);

  return (
    <Sonner
      theme={theme as ToasterProps["theme"]}
      className="toaster group"
      icons={{
        success: <SuccessToastIcon />,
        error: <ErrorToastIcon />,
      }}
      toastOptions={{
        classNames: {
          toast:
            "group toast group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg",
          description: "group-[.toast]:text-muted-foreground",
          actionButton:
            "group-[.toast]:bg-primary group-[.toast]:text-primary-foreground ml-auto",
          cancelButton:
            "group-[.toast]:bg-muted group-[.toast]:text-muted-foreground",
          icon: "h-auto w-auto",
        },
      }}
      {...props}
    />
  );
};

export const SuccessToastIcon = () => (
  <CheckCircledIcon className="text-green-500 size-4" aria-label="Success" />
);

export const ErrorToastIcon = () => (
  <CheckCircledIcon className="text-red-500 size-4" aria-label="Error" />
);

export const ToastLink = ({
  to,
  children,
}: PropsWithChildren<{ to: string }>) => (
  <Button asChild variant="link" className="ml-auto p-0" size="link">
    <Link to={to}>{children}</Link>
  </Button>
);

export { Toaster };
